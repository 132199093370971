<template>
  <div>
    <pro-form-render
      mode="Edit"
      :code="workFlowCode"
      :form-data-promise="formDataPromise"
      @pro-form-submit="redirectToHcmDashboard()"
    />
  </div>
</template>

<script>
import ProFormRender from "@/components/PROSmart/FormBuilder/ProFormRender";
export default {
  name: "SupplierUpdatePrincipalEmailAddress",
  components: { ProFormRender },

  data() {
    return {
      workFlowCode: "S_E_PrincipalEmail",
      formDataPromise: null,
    };
  },

  created() {
    this.$proSmart.supplierUi.getBasicInfo(this).then(({ emailAddress }) => {
      this.formDataPromise = Promise.resolve({
        emailAddress,
        emailAddressPre: emailAddress,
      });
    });
  },

  methods: {
    redirectToHcmDashboard() {
      const path = "/UpdatePrincipalEmailAddress";
      this.$store.dispatch("menu/removeSubMenu", path);
      this.$router.push({ path: "/Dashboard" });
    },
  },
};
</script>

<style lang="scss" scoped></style>
